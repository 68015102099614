
import { Options, Vue } from "vue-class-component";
import Input from "@/app/ui/components/input/index.vue";
import { ProductController } from "@/app/ui/controllers/ProductController";
import router from "@/app/ui/router";
import { convertArrayToString } from "@/app/infrastructures/misc/Utils";

@Options({
  components: {
    Input
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (this.hasChanged && !this.openSuccess) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      ProductController.setOpenModalSuccess(false);
      this.name = "";
      next();
    }
  }
})
export default class Add extends Vue {
  goBack() {
    this.$router.back();
  }

  item = {
    status: "Active"
  };

  isDisabled = true;

  // Toggle Route
  openRoute = false;
  onOpenRoute() {
    this.openRoute = true;
  }
  isRoute = false;
  onCloseRoute() {
    this.openRoute = false;
  }
  changeRoute() {
    this.openRoute = false;
    this.isRoute = !this.isRoute;
  }

  // Modal Success
  messageSuccess = "";
  get openSuccess() {
    return ProductController.openModalSuccess;
  }
  onCloseSuccess() {
    router.push(`/master-data/product`);
  }

  // Save data
  get openSave() {
    return ProductController.openModal;
  }
  onOpenSave() {
    ProductController.setOpenModal(true);
  }
  onCloseSave() {
    ProductController.setOpenModal(false);
  }
  _onCreate() {
    ProductController._onAddProduct({
      name: this.name.toUpperCase(),
      cargoProductType: this.convertToString(this.eCargoProduct, "value").split(
        ","
      ),
      description: this.description,
      status: this.isRoute ? "active" : "inactive"
    });
  }

  // Convert Value
  convertToString(data: Array<any>, key: string) {
    return convertArrayToString(data, key);
  }

  // Close when changed
  isEditTerminated = false;
  newPath = "";
  answer = false;

  get hasChanged() {
    return (
      this.name !== "" ||
      this.description !== "" ||
      this.isRoute !== !this.isRoute
    );
  }

  onOpenClosePage(to: any) {
    this.isEditTerminated = true;
    this.newPath = to.path;
  }

  handlerClose() {
    this.isEditTerminated = false;
    this.answer = true;
    this.$router.push(this.newPath);
  }

  handlerCancelClose() {
    this.answer = false;
    this.isEditTerminated = false;
  }

  // eCargo Product
  eCargoProductListData: Array<any> = [
    { name: "Express port to port", value: "Express" },
    { name: "REGULAR", value: "REGULAR" }
  ];
  eCargoProduct = [];
  get isLoadingType() {
    return false;
  }

  // validation name
  name = "";

  checkName(value: any) {
    if (this.online) {
      ProductController.searchProduct(value);
    }
    this.name = value;
  }

  get dataUser() {
    return ProductController.productData.data;
  }

  validateName(e: any) {
    e.target.value = e.target.value.replace(/[^a-zA-Z0-9]+/g, "");
    if (this.name !== "") {
      this.checkName(e.target.value);
    }
    this.isDisable();
  }

  // validation description
  description = "";
  validateDescription(e: any) {
    this.isDisable();
  }

  // disable
  disableButton = true;
  isDisable() {
    this.disableButton = !(this.description !== "" && this.name !== "");
  }

  // check online status
  online = window.navigator.onLine;
}
