
import { Options, Vue } from "vue-class-component";
import TableV1 from "@/app/ui/components/table/index.vue";
import router from "@/app/ui/router";
import { ProductController } from "@/app/ui/controllers/ProductController";
import debounce from "lodash/debounce";
import { checkRolePermission } from "@/app/infrastructures/misc/Utils";
import { PRICING_PRODUCT } from "@/app/infrastructures/misc/RolePermission";

@Options({
  components: {
    TableV1
  },
  computed: {
    getCurrentRoute() {
      return this.$route.meta.title;
    },
    isShowTable() {
      return this.listData.length > 0 && !this.isLoading && !this.isError;
    },
    isCreateAble() {
      return checkRolePermission(PRICING_PRODUCT.CREATE) && !this.isError;
    },
    isDetailAble() {
      return checkRolePermission(PRICING_PRODUCT.DETAIL);
    }
  }
})
export default class LegManagement extends Vue {
  // Breadcrumb
  breadcrumb: Array<string> = [];

  // Filter
  statusData = [
    { name: "Semua Status", value: "" },
    { name: "Active", value: "active" },
    { name: "Inactive", value: "inactive" }
  ];
  statusSelect = false;
  statusName = "";
  statusValue = "";

  onOpenStatusSelect() {
    this.statusSelect = true;
  }
  onCloseStatusSelect() {
    this.statusSelect = false;
  }
  onSelectStatus(name: string, value: string) {
    this.onCloseStatusSelect();
    ProductController.selectStatusAction(value);
    this.firstPage();
    this.statusName = name;
    this.statusValue = value;
  }

  created() {
    this.getProductList();
    this.firstPage();
  }

  getProductList() {
    return ProductController.getProductList({
      search: "",
      status: "",
      page: 1,
      limit: 10,
      productCode: ""
    });
  }

  firstPage() {
    return ProductController.setFirstPage();
  }

  get listData() {
    return ProductController.productData.data;
  }

  columns = [
    {
      name: "ID Produk",
      key: "id",
      styleHead: "w-1/12 text-center whitespace-no-wrap",
      styleBody: "",
      render: (item: any) => {
        return `<div class="flex justify-center"><span class='text-center text-red-lp-200'>${item.id}</span></div>`;
      }
    },
    {
      name: "Tipe Produk",
      key: "productType",
      styleHead: "w-2/12 text-center whitespace-no-wrap",
      styleBody: "",
      render: (item: any) => {
        return `<div class='flex justify-center'>${item.name}</div>`;
      }
    },
    {
      name: "eCargo Product",
      key: "productType",
      styleHead: "w-2/12 text-left whitespace-no-wrap",
      styleBody: "",
      render: (item: any) => {
        return `<div class='flex text-left'>${
          item.eCargoProduct === "" ? "-" : item.eCargoProduct
        }</div>`;
      }
    },
    {
      name: "Deskripsi",
      key: "description",
      styleHead: "w-6/12 xxl:w-5/12 text-left",
      styleBody: "text-left break-words",
      render: (item: any) => {
        return `<div class='ellipsis-2'>${
          item.description === "" ? "-" : item.description
        }</div>`;
      }
    },
    {
      name: "Status",
      key: "status",
      styleHead: "w-2/12 text-center",
      styleBody: "",
      render: (item: any) => {
        return `
        <div class="flex justify-center">
      <div
        class="rounded-full px-2 py-0 ${
          item.status.toLowerCase() === "active"
            ? "bg-green-lp-300"
            : "bg-red-300"
        } ${
          item.status.toLowerCase() === "active"
            ? "text-green-lp-200"
            : "text-red-lp-200"
        }"
      ><span class="capitalize">${item.status.toLowerCase()}</span></div></div>`;
      }
    }
  ];

  // To Detail
  onClickRow(item: any) {
    router.push(`/master-data/product/${item.id}`);
  }

  // to Add Page
  toPageAdd() {
    router.push(`/master-data/product/add`);
  }

  //Loading
  get isLoading() {
    return ProductController.isLoading;
  }

  //Error
  get isError() {
    return ProductController.isError;
  }

  //Error Cause
  get errorCause() {
    return ProductController.errorCause;
  }

  // Pagination
  get page() {
    return ProductController.page;
  }
  get limit() {
    return ProductController.limit;
  }
  nextPage() {
    ProductController.setNextPage();
  }

  prevPage() {
    ProductController.setPrevPage();
  }

  toPage = debounce(value => {
    ProductController.setPageAction(value);
  }, 500);
}
