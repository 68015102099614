
import { Options, Vue } from "vue-class-component";
import Input from "@/app/ui/components/input/index.vue";
import { ProductController } from "@/app/ui/controllers/ProductController";
import {
  convertArrayToString,
  formatDate
} from "@/app/infrastructures/misc/Utils";
import router from "@/app/ui/router";

@Options({
  components: {
    Input
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (!this.openSave() && !this.openModalSuccess) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      ProductController.setOpenModalSuccess(false);
      next();
    }
  }
})
export default class Edit extends Vue {
  created() {
    this.fetchProductData();
  }
  goBack() {
    this.$router.back();
  }

  id() {
    return this.$route.params.id;
  }

  item = {
    status: "Active"
  };

  isDisabled = true;

  // Detail data
  fetchProductData() {
    this.getDetailProductData(this.$route.params.id);
  }

  getDetailProductData(id: any) {
    return ProductController.getDetailProduct({ id });
  }
  createdAt = "";
  createdBy = "";
  updateAt = "";
  updateBy = "";

  get dataDetail() {
    const detail = ProductController.productDetail;
    this.createdAt = formatDate(detail.createdAt);
    this.createdBy = detail.createdBy;
    this.updateAt =
      detail.updateAt === "0001-01-01T00:00:00Z" || detail.updateAt === null
        ? formatDate(detail.createdAt)
        : formatDate(detail.updateAt);
    this.updateBy = detail.updatedBy;
    return detail;
  }

  // eCargo Product
  eCargoProductListData: Array<any> = [
    { name: "Express port to port", value: "express" },
    { name: "REGULAR", value: "REGULAR" }
  ];
  get eCargoProduct() {
    return ProductController.eCargoProductDefault;
  }
  get isLoadingType() {
    return false;
  }

  // Toggle Route
  openRoute = false;
  onOpenRoute(e: any) {
    this.openRoute = true;
    this.filterInput(e);
  }
  onCloseRoute() {
    this.openRoute = false;
  }
  isRoute() {
    return ProductController.isRoute;
  }
  changeRoute() {
    ProductController.setRoute(!this.isRoute());
    this.openRoute = false;
    this.messageSuccess = `Detail Produk "${this.dataDetail.name}" berhasil diubah`;
    this.openSuccess = true;
  }
  cacheRoute() {
    return ProductController.cacheRoute;
  }
  routeForComparison() {
    return this.isRoute();
  }

  get description() {
    return ProductController.description;
  }

  get cacheDescription() {
    return ProductController.cacheDescription;
  }

  inputDescription(value: any) {
    ProductController.setDescription(value);
  }

  // Modal Success after Save
  get openModalSuccess() {
    return ProductController.openModalSuccess;
  }
  onCloseModalSuccess() {
    router.push(`/master-data/product/${this.$route.params.id}`);
  }

  // Modal Success
  messageSuccess = "";
  openSuccess = false;
  onCloseSuccess() {
    this.openSuccess = false;
  }

  // Save data
  openSave() {
    return ProductController.openModal;
  }
  onOpenSave() {
    ProductController.setOpenModal(true);
  }
  onCloseSave() {
    ProductController.setOpenModal(false);
  }

  _onEdit() {
    ProductController._onEditProduct({
      id: Number(this.id()),
      cargoProductType: this.convertToString(this.eCargoProduct, "value").split(
        ","
      ),
      isActive: this.isRoute() ? "active" : "inactive",
      description: this.description
    });
  }

  // Close when changed
  isEditTerminated = false;
  newPath = "";
  answer = false;

  get hasChanged() {
    return (
      this.cacheRoute() !== this.routeForComparison() ||
      this.description !== this.cacheDescription
    );
  }

  onOpenClosePage(to: any) {
    this.isEditTerminated = true;
    this.newPath = to.path;
  }

  handlerClose() {
    this.isEditTerminated = false;
    this.answer = true;
    this.$router.push(this.newPath);
  }

  handlerCancelClose() {
    this.answer = false;
    this.isEditTerminated = false;
  }

  filterInput(e: any) {
    e.target.value = e.target.value.replace(/[\D]+/g, "");
  }

  //Error
  get isError() {
    return ProductController.isError;
  }

  //Error Cause
  get errorCause() {
    return ProductController.errorCause;
  }

  handleErrorClose() {
    ProductController.handleError();
  }

  // Convert Value
  convertToString(data: Array<any>, key: string) {
    return convertArrayToString(data, key);
  }
}
