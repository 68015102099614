
import { Options, Vue } from "vue-class-component";
import { ProductController } from "@/app/ui/controllers/ProductController";
import {
  checkRolePermission,
  formatDate
} from "@/app/infrastructures/misc/Utils";
import { PRICING_PRODUCT } from "@/app/infrastructures/misc/RolePermission";

@Options({
  components: {},
  computed: {
    id() {
      return this.$route.params.id;
    },
    isEditAble() {
      return checkRolePermission(PRICING_PRODUCT.EDIT);
    }
  }
})
export default class DetailProduct extends Vue {
  async created() {
    this.fetchProductData();
  }

  //Loading
  get isLoading() {
    return ProductController.isLoadingDetail;
  }

  fetchProductData() {
    this.getDetailProductData(this.$route.params.id);
  }

  getDetailProductData(id: any) {
    return ProductController.getDetailProduct({ id });
  }

  //Error
  get isError() {
    return ProductController.isError;
  }

  //Error Cause
  get errorCause() {
    return ProductController.errorCause;
  }

  createdAt = "";
  createdBy = "";
  updateAt = "";
  updateBy = "";

  get dataDetail() {
    const detail = ProductController.productDetail;
    this.createdAt = formatDate(detail.createdAt);
    this.createdBy = detail.createdBy;
    this.updateAt =
      detail.updateAt === "0001-01-01T00:00:00Z" || detail.updateAt === null
        ? formatDate(detail.createdAt)
        : formatDate(detail.updateAt);
    this.updateBy = detail.updatedBy;
    return detail;
  }

  goBack() {
    this.$router.push(`/master-data/product`);
  }

  item = {
    status: "active"
  };

  goEdit(id: string) {
    this.$router.push(`/master-data/product/${id}/edit`);
  }
}
